import React, { useState, useContext, useEffect, useRef } from "react";
import Proptypes from "prop-types";
import { Col, Form, Input, Row, Button, Select } from "antd";
import { navigate } from "gatsby";

import PhoneInputAntd from "../common/PhoneInputAntd";
import { TITLES } from "../../utils/constants";
import { updateUser, checkEmail } from "../../utils/api";
import {
  UserDispatchContext,
  UserStateContext,
} from "../../context/UserContextProvider";
import { CountrySearch } from "../common/CountrySearch";
import {
  AuthDispatchContext,
  AuthStateContext,
} from "../../context/AuthContextProvider";
// @ts-expect-error
import back from "../../images/back.svg";

function SignupCommon() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [hydrationDone, setHydrationDone] = useState(false);
  const UserDispatch = useContext(UserDispatchContext);
  const AuthDispatch = useContext(AuthDispatchContext);
  const { user } = useContext(UserStateContext);
  const { accessToken: token } = useContext(AuthStateContext);
  const phoneRef = useRef();

  useEffect(() => {
    const flagDropDownElements = document.getElementsByClassName(
      "flag-dropdown"
    );

    if (flagDropDownElements[0]) {
      flagDropDownElements[0].setAttribute("tabindex", "-1");
    }
  }, []);

  useEffect(() => {
    if (hydrationDone) return;
    setHydrationDone(true);
    // phoneRef.current.setCountry("sg");
    if (user) {
      const {
        title,
        fname,
        lname,
        countryOfResidence,
        phone,
        email,
        selectedDiligenceForm,
      } = user;
      form.setFieldsValue({
        title,
        fname,
        lname,
        countryOfResidence,
        phone,
        email,
        selectedDiligenceForm,
      });
      if (!phone)
        // @ts-ignore
        phoneRef.current.setCountry((countryOfResidence || "sg").toLowerCase());
    } else {
      // @ts-ignore
      phoneRef.current.setCountry("sg");
    }
  }, [user]);

  const syncData = (onSuccess?, extra = {}) => {
    const values = form.getFieldsValue();

    if (values.title) values.title = values.title.toLowerCase();

    const {
      email,
      phone,
      title,
      lname,
      fname,
      countryOfResidence,
      selectedDiligenceForm,
    } = values;

    const valuesOfInterrest = {
      email,
      phone,
      title,
      lname,
      fname,
      countryOfResidence,
      selectedDiligenceForm,
      ...extra,
    };

    if (token !== null && user && user.id) {
      updateUser(user.id, token, valuesOfInterrest)
        .then(async r => {
          if (r.ok) {
            const { user: newUser } = await r.json();
            if (onSuccess) onSuccess({ user: newUser });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="signup_slide">
      <Row
        onClick={() => {
          navigate("/dashboard");
        }}
        style={{ width: "100%" }}
      >
        <Row
          style={{ marginBottom: 50, cursor: "pointer" }}
          gutter={10}
          justify="start"
          align="middle"
        >
          <Col>
            <img src={back} alt="" />
          </Col>
          <Col>
            <h3
              style={{ lineHeight: "23px", margin: 0 }}
              className="section-title"
            >
              Back to List
            </h3>
          </Col>
        </Row>
      </Row>
      <Form
        form={form}
        layout="vertical"
        name="register"
        size="large"
        style={{ maxWidth: "700px", width: "100%" }}
        onBlur={() => {
          syncData();
        }}
        onFinish={() => {
          setIsLoading(true);
          syncData(
            r => {
              if (r) {
                AuthDispatch({ type: "set", payload: r });
                UserDispatch({ type: "set", payload: r });
              }
            },
            { sendPhoneCode: true }
          );
        }}
        scrollToFirstError
      >
        <Row gutter={20}>
          <Col xs={24} md={4}>
            <Form.Item
              label="Title"
              name="title"
              initialValue="mr"
              rules={[{ required: true, message: "Please input your title" }]}
            >
              <Select placeholder="Title">
                {Object.values(TITLES).map(e => (
                  <Select.Option key={e} value={e}>
                    {e}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={10}>
            <Form.Item
              label="First Name"
              name="fname"
              rules={[
                { required: true, message: "Please input your first name" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={10}>
            <Form.Item
              label="Last Name"
              name="lname"
              rules={[
                { required: true, message: "Please input your last name" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Country"
          name="countryOfResidence"
          initialValue="SG"
          rules={[{ required: true, message: "Please input your country" }]}
        >
          <CountrySearch
            onChange={c => {
              if (phoneRef.current)
                // @ts-ignore
                phoneRef.current.setCountry(c.toLowerCase());
            }}
          />
        </Form.Item>
        <Row gutter={10}>
          <Col xs={24} md={12}>
            <Form.Item shouldUpdate>
              {() => (
                <Form.Item
                  label="Phone"
                  name="phone"
                  getValueFromEvent={e => e}
                  rules={[
                    { required: true, message: "Please input your phone" },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <PhoneInputAntd ref={phoneRef} />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Email"
              name="email"
              validateTrigger="onBlur"
              rules={[
                {
                  validator: (r, v) => {
                    // eslint-disable-next-line no-async-promise-executor
                    return new Promise(async (res, rej) => {
                      if (v) {
                        try {
                          const result = await checkEmail({
                            token,
                            email: v,
                            userId: user.id,
                          });
                          if (result.ok) {
                            const rr = await result.json();
                            if (rr.success) {
                              res();
                              return;
                            }
                          }
                          rej("This email already exists.");
                        } catch (e) {
                          console.log(e);
                          rej("This email already exists.");
                        }
                      } else {
                        res();
                      }
                    });
                  },
                },
                { type: "email", message: "Email invalide" },
                { required: true, message: "Please enter your email" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <hr />

        <Row style={{ marginTop: "34px" }} gutter={24}>
          <Col
            xs={24}
            md={{ span: 12, offset: 12 }}
            style={{ textAlign: "right" }}
          >
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.agree !== curValues.agree
              }
            >
              {() => {
                return (
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => {
                        form.submit();
                      }}
                      loading={isLoading}
                      className="main-btn"
                    >
                      Save
                    </Button>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

SignupCommon.propTypes = {
  onDiligenceFormChange: Proptypes.func,
};

export default SignupCommon;
