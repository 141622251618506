import React, { useState, useContext, useEffect } from "react";
import { Col, Form, Row, Upload, message, Input, Radio } from "antd";
import Moment from "moment";
// @ts-ignore
import UploadFileIcon from "../../../images/upload-files-icon.svg";

import { createDilligenceForm } from "../../../utils/api";
import {
  DILIGENCE_FORMS,
  // DOCUMENT_TYPES,
  ENDPOINTS,
  COUNTRIES_LIST,
} from "../../../utils/constants";
import {
  UserStateContext,
  UserDispatchContext,
} from "../../../context/UserContextProvider";
import { Nationalities } from "../../common/Nationalities";
import { AddressItem } from "../../common/PlacesAutocomplete";
import { AuthStateContext } from "../../../context/AuthContextProvider";
import { deleteUploadedDocument } from "../../../utils/deleteUploadedDocument";
import { CountrySearch } from "../../common/CountrySearch";
import { DateInput } from "../../common/DateInput";
import RequiredFile from "../../common/RequiredFile";

function Individual({ form }: any) {
  const [df, setDF] = useState(null);
  // to ensure we don't create an object twice
  const [isCreatingDiligenceForm, setIsCreatingDiligenceForm] = useState(false);
  const { user, diligenceForm, documents } = useContext(UserStateContext);
  const [docs, setDocs] = useState([]);
  const UserDispatch = useContext(UserDispatchContext);

  useEffect(() => {
    if (diligenceForm) setDF(diligenceForm);
    if (diligenceForm) {
      const {
        nationalities,
        placeOfBirth,
        address,
        // originOfAssets,
        isAPep,
        addressDetail,
      } = diligenceForm;
      const formValues: any = {
        isAPep,
        address: { ...address, input: (address && address.asStr) || "" },
        nationalities,
        addressDetail,
      };

      // (nationalities || []).forEach((nat, i) => {
      //   formValues[`nationality-${i}`] = nat;
      // });

      // if (originOfAssets) {
      //   formValues.originOfAssets = originOfAssets.origin;
      //   formValues.sourceOfAssets = originOfAssets.detail;
      // }

      if (placeOfBirth) {
        formValues.birthCountry = placeOfBirth.country;
        formValues.birthCity = placeOfBirth.city;
        formValues.birthDate = placeOfBirth.birthDate;
        formValues.isBirthPlaceNationality =
          (placeOfBirth.isBirthPlaceNationality && "true") || "false";
      }

      form.setFieldsValue(formValues);
    } else {
      form.resetFields();
    }
  }, [diligenceForm]);

  const { accessToken } = useContext(AuthStateContext);

  useEffect(() => {
    setDocs(
      (documents || []).map((d, j) => ({
        uid: d.uid || j,
        status: d.status || "done",
        response: { document: d, url: d.url },
        url: d.url,
      }))
    );
  }, [documents]);

  return (
    <div className="signup_slide">
      <div style={{ marginTop: "30px" }}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Where are you born ?"
              required
              rules={[
                {
                  required: true,
                  message: "Please select country of birth",
                },
              ]}
              name="birthCountry"
              initialValue={user && user.countryOfResidence}
            >
              <CountrySearch />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              shouldUpdate={(o, n) => o.birthCountry !== n.birthCountry}
            >
              {() => {
                const birthCountry = COUNTRIES_LIST.find(
                  e => e.code === form.getFieldValue("birthCountry")
                );
                return (
                  <Form.Item
                    name="birthCity"
                    label={`Where in ${
                      birthCountry ? birthCountry.name : ""
                    } ? `}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your city of birth",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className="banner" style={{ marginBottom: "25px" }}>
          <Col xs={24} md={12}>
            <Form.Item
              name="birthDate"
              label="When are you born ?"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  validator: (r, v) => {
                    return new Promise((res, rej) => {
                      if (v) {
                        const d = Moment(v, "M/D/YYYY", true);
                        if (
                          d.isValid() &&
                          // @ts-ignore
                          d.isAfter(new Moment().subtract(110, "years"))
                        ) {
                          if (
                            // @ts-ignore
                            d.isBefore(new Moment().subtract(18, "years"))
                          ) {
                            res();
                          } else {
                            rej("You should be older then 18 years");
                          }
                        } else rej("Please enter a valid date");
                      } else {
                        rej("Please enter your date of birth");
                      }
                    });
                  },
                },
              ]}
            >
              <DateInput placeholder="MM/DD/YYYY" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="isBirthPlaceNationality"
              label="Do you have this nationality ?"
            >
              <Radio.Group
                className="nationality-check"
                onChange={e => {
                  if (
                    e.target.value === "false" &&
                    form.getFieldValue("nationalities").length === 0
                  )
                    form.setFieldsValue({ nationalities: [null] });
                }}
              >
                <Radio.Button style={{ width: 137 }} value="true">
                  Yes
                </Radio.Button>
                <Radio.Button style={{ width: 137 }} value="false">
                  No
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item noStyle name="nationalities" initialValue={[]}>
          <Nationalities user={user} form={form} />
        </Form.Item>

        <Row gutter={8}>
          <Col xs={24}>
            <AddressItem />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item name="addressDetail" label="Address details">
              <Input placeholder="add additional information" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <div className="section-info">
            <p>
              Global Precious Metals upholds a strict anti-financial crimes
              policy, and we need to verify your identity and residence. We
              proudly abide by international standards, including but not
              limited to :
            </p>
            <ul>
              <li>
                {" "}
                Singapore’s Corruption, Drug Trafficking and Other Serious
                Crimes (Confiscation of Benefits) Act, Chapter 65A;
              </li>
              <li>
                {" "}
                Singapore’s Terrorism (Suppression of Financing) Act, Chapter
                325; and
              </li>
              <li>
                {" "}
                Singapore’s Precious Stones and Precious Metals (Prevention of
                Money Laundering and Terrorism Financing) Act.
              </li>
            </ul>
          </div>
        </Row>
        <div style={{ color: "#000" }}>
          <h6>PLease upload the following documents:</h6>
        </div>

        <div className="upload-section">
          <RequiredFile text="Copy of passport/Id card" />
          <RequiredFile text="Copy of proof of residence" />

          <Form.Item>
            <Upload.Dragger
              className="documents-dragger"
              name="file"
              listType="picture-card"
              multiple
              data={{
                // docType: e.docType,
                userId: user && user.id,
                diligenceFormId: df && df.id,
              }}
              action={(): Promise<string> => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (res, rej) => {
                  try {
                    let dil = df;
                    if (!df) {
                      if (isCreatingDiligenceForm) rej();
                      setIsCreatingDiligenceForm(true);
                      const re = await createDilligenceForm(
                        DILIGENCE_FORMS.INDIVIDUAL
                      )(accessToken, {});
                      dil = (await re.json()).diligenceForm;

                      setDF(dil);
                      setIsCreatingDiligenceForm(false);
                      UserDispatch({
                        type: "set",
                        payload: { diligenceForm: dil },
                      });
                    }
                    res(`${ENDPOINTS.DILIGENCE_FORM}/${dil.id}/document`);
                  } catch (ee) {
                    rej(ee);
                  }
                });
              }}
              headers={{
                Authorization: `Bearer ${accessToken}`,
              }}
              onChange={info => {
                const { status } = info.file;

                const fileList = [...info.fileList];
                setDocs(
                  fileList.map(file => {
                    if (file.response && file.response.document) {
                      // Component will show file.url as link

                      // eslint-disable-next-line no-param-reassign
                      file.url = file.response.document.url;
                    }
                    return file;
                  })
                );

                if (status === "done") {
                  message.success(
                    `${info.file.name} file uploaded successfully.`
                  );
                } else if (status === "error") {
                  message.error(`${info.file.name} file upload failed.`);
                }
              }}
              onRemove={deleteUploadedDocument(accessToken)}
              fileList={docs || []}
            >
              <div className="dragArea">
                <img src={UploadFileIcon} alt="UploadIcon" />
                <div className="dragArea_uploadTextConatiner">
                  <div className="dragArea_uploadTitle">
                    Drag your files here
                  </div>
                  <div className="dragArea_uploadDesc">
                    Support extension: zip .doc .docx .pdf .jpg...
                  </div>
                </div>
                <div className="dragArea_uploadBtn">Upload a document</div>
              </div>
            </Upload.Dragger>
          </Form.Item>
        </div>
        <div className="section-note">
          In specific cases, Global Precious Metals might request a copy of
          documents suitably certified (e.g. by professionals such as a lawyer,
          a notary public, an accountant, a government official or a senior
          manager at a regulated financial institution)
        </div>
      </div>
    </div>
  );
}

export default Individual;
