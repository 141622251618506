import React, { useContext, useState, useEffect } from "react";
import {
  Select,
  Form,
  Row,
  Col,
  Input,
  Upload,
  message,
  DatePicker,
} from "antd";
import * as Moment from "moment";
// @ts-ignore
import UploadFileIcon from "../../../images/upload-files-icon.svg";
import { UserStateContext } from "../../../context/UserContextProvider";
import { DILIGENCE_FORMS, ENDPOINTS } from "../../../utils/constants";
import { createDilligenceForm } from "../../../utils/api";
import { CountrySearch } from "../../common/CountrySearch";
import { AuthStateContext } from "../../../context/AuthContextProvider";
import { deleteUploadedDocument } from "../../../utils/deleteUploadedDocument";
import { AddressItem } from "../../common/PlacesAutocomplete";
import RequiredFile from "../../common/RequiredFile";

function CorporateEntitySlide1({ diligenceFormId, entityTypes, form }: any) {
  const [df, setDF] = useState(null);
  const { diligenceForm, documents, user } = useContext(UserStateContext);
  const { accessToken } = useContext(AuthStateContext);
  const [upladDocuments, setUpladDocuments] = useState([]);

  useEffect(() => {
    setUpladDocuments(
      (documents || []).map((d, j) => ({
        uid: d.uid || j,
        status: d.status || "done",
        response: { document: d, url: d.url },
        url: d.url,
      }))
    );
  }, [documents]);

  useEffect(() => {
    form.setFieldsValue({
      // @ts-ignore
      entityType: entityTypes && Object.values(entityTypes)[0].KEY,
    });
  }, [entityTypes]);

  useEffect(() => {
    if (diligenceForm) {
      setDF(diligenceForm);
      const {
        entityType,
        name,
        address,
        registrationId,
        countryOfIncorporation,
        dateOfIncorporation,
        addressDetail,
      } = diligenceForm;
      const formValues: any = {
        addressDetail,
        entityType,
        name,
        registrationId,
        countryOfIncorporation,
        dateOfIncorporation:
          (dateOfIncorporation && Moment(dateOfIncorporation)) ||
          dateOfIncorporation,
        address: { ...address, input: (address && address.asStr) || "" },
      };

      form.setFieldsValue(formValues);
    }
  }, [diligenceForm, diligenceFormId]);

  useEffect(() => {
    if (diligenceForm) {
      const {
        entityType,
        name,
        registrationId,
        countryOfIncorporation,
        dateOfIncorporation,
        address,
        addressDetail,
      } = diligenceForm;
      const formValues = {
        entityType,
        name,
        registrationId,
        countryOfIncorporation,
        dateOfIncorporation: dateOfIncorporation
          ? Moment(dateOfIncorporation)
          : null,
        address: {
          ...address,
          input: (address && address.asStr) || "",
        },
        addressDetail,
      };
      form.setFieldsValue(formValues);
    } else {
      form.resetFields();
    }
  }, [diligenceFormId, diligenceForm]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 700,
        width: "100%",
        margin: "0 auto",
      }}
    >
      <Row align="middle">
        <Col xs={24} md={12}>
          <Form.Item
            name="entityType"
            label="Your entity is a"
            rules={[
              {
                required: true,
                message: "Please select your entity type",
              },
            ]}
          >
            <Select placeholder="Select a type">
              {Object.values(entityTypes).map(
                (e: { KEY: string; VALUE: string }) => (
                  <Select.Option key={e.KEY} value={e.KEY}>
                    {e.VALUE}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col xs={24} md={12}>
          <Form.Item
            name="name"
            label="Entity name"
            rules={[
              { required: true, message: "Please input your entity's name" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="registrationId"
            label="Registration ID"
            rules={[
              {
                required: true,
                message: "Please input your registration id",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col xs={24} md={12}>
          <Form.Item
            name="countryOfIncorporation"
            label="Country of incorporation"
            rules={[
              {
                required: true,
                message: "Please select the country of incorporation",
              },
            ]}
          >
            <CountrySearch />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="dateOfIncorporation"
            label="Date of incorporation"
            rules={[
              {
                required: true,
                message: "Please select the date of incorporation",
              },
            ]}
            getValueFromEvent={e => e}
          >
            <DatePicker
              disabledDate={d =>
                // @ts-ignore
                d.valueOf() > new Moment().subtract(1, "day").valueOf()
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={24}>
          <AddressItem
            itemProps={{
              name: "address",
              label: "Address",
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <Form.Item name="addressDetail" label="Address details">
            <Input placeholder="add additional information" />
          </Form.Item>
        </Col>
      </Row>

      <div className="section-title text-lower">
        <p className="capitalized" style={{ fontSize: 18 }}>
          Please upload the following documents:
        </p>
      </div>

      <div className="upload-section">
        <RequiredFile text="Certificate of incorporation" />
        <RequiredFile text="Memorandum and Articles of Association " />
        <RequiredFile text="List or register of directors and shareholders " />
        <RequiredFile text="Copies of passports of all directors and shareholders" />
        <Form.Item shouldUpdate={(o, n) => o.entityType !== n.entityType}>
          {() => (
            <Form.Item>
              <Upload.Dragger
                className="documents-dragger"
                name="file"
                listType="picture-card"
                multiple
                data={{
                  // docType: e.docType,
                  userId: user && user.id,
                  diligenceFormId: df && df.id,
                }}
                action={(): Promise<string> => {
                  // eslint-disable-next-line no-async-promise-executor
                  return new Promise(async (res, rej) => {
                    try {
                      let dil = df;
                      if (!df) {
                        // if (isCreatingDiligenceForm) rej();
                        // setIsCreatingDiligenceForm(true);
                        const re = await createDilligenceForm(
                          DILIGENCE_FORMS.INDIVIDUAL
                        )(accessToken, {});
                        dil = (await re.json()).diligenceForm;

                        setDF(dil);
                        // setIsCreatingDiligenceForm(false);
                      }
                      res(`${ENDPOINTS.DILIGENCE_FORM}/${dil.id}/document`);
                    } catch (ee) {
                      rej(ee);
                    }
                  });
                }}
                headers={{
                  Authorization: `Bearer ${accessToken}`,
                }}
                onChange={info => {
                  const { status } = info.file;

                  const fileList = [...info.fileList];
                  setUpladDocuments(
                    fileList.map(file => {
                      if (file.response && file.response.document) {
                        // Component will show file.url as link

                        // eslint-disable-next-line no-param-reassign
                        file.url = file.response.document.url;
                      }
                      return file;
                    })
                  );
                  if (status === "done") {
                    message.success(
                      `${info.file.name} file uploaded successfully.`
                    );
                  } else if (status === "error") {
                    message.error(`${info.file.name} file upload failed.`);
                  }
                }}
                onRemove={deleteUploadedDocument(accessToken)}
                fileList={upladDocuments}
              >
                <div className="dragArea">
                  <img src={UploadFileIcon} alt="UploadIcon" />
                  <div className="dragArea_uploadTextConatiner">
                    <div className="dragArea_uploadTitle">
                      Drag your files here
                    </div>
                    <div className="dragArea_uploadDesc">
                      Support extension: zip .doc .docx .pdf .jpg...
                    </div>
                  </div>
                  <div className="dragArea_uploadBtn">Upload a document</div>
                </div>
              </Upload.Dragger>
            </Form.Item>
          )}
        </Form.Item>
      </div>
      <div className="section-note">
        <p>
          Unless otherwise specified, all non-original documents must be
          certified by a suitable certifier (which includes professionals such
          as a lawyer, a notary public, an accountant, a government official or
          a senior manager at a regulated financial institution)
        </p>
      </div>

      {/* <Row style={{ marginTop: "34px" }} gutter={24}> */}
      {/* <Col xs={24} md={{ span: 10, offset: 14 }} style={{ textAlign: "right" }}>
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
          loading={isLoading}
          block
          size="large"
          className="main-btn"
        >
          Validate
        </Button>
      </Col> */}
      {/* </Row> */}
    </div>
  );
}

export default CorporateEntitySlide1;
