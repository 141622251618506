import React, { useState, useContext, useEffect } from "react";
import { navigate } from "gatsby";
import { Edit } from "../../components/Edit";
import { AuthStateContext } from "../../context/AuthContextProvider";
import { UserDispatchContext } from "../../context/UserContextProvider";
import { getCookie } from "../../utils/getCookie";
import { fetchUser } from "../../utils/api";
import Layout from "../../components/Layout";
import Loader from "../../components/common/Loader";

export default ({ location }: any) => {
  const [error] = useState(null);
  const [isDownloadingUser, setIsDownloadingUser] = useState(true);
  const { accessToken } = useContext(AuthStateContext);
  const UserDispatch = useContext(UserDispatchContext);

  const token = getCookie("accessToken") || accessToken;
  useEffect(() => {
    const query = location.search
      .slice(1)
      .split("&")
      .reduce((acc, e) => {
        const d = e.split("=");
        return { ...acc, [d[0]]: d[1] };
      }, {});
    if (!query || !query.id) navigate("/");
    if (token && query.id) {
      setIsDownloadingUser(true);
      fetchUser({ userId: query.id, token })
        .then(async r => {
          if (r.status === 401) navigate("/");
          if (r.ok) {
            const {
              user,
              diligenceForm,
              documents,
              userDocuments,
            } = await r.json();
            UserDispatch({
              type: "set",
              payload: { user, diligenceForm, documents, userDocuments },
            });
          }
        })
        .finally(() => {
          setIsDownloadingUser(false);
        });
    }
  }, [location, token]);

  return (
    <Layout>
      {isDownloadingUser ? (
        <div>
          <Loader />
        </div>
      ) : error ? (
        <div>Not found</div>
      ) : (
        <Edit />
      )}
    </Layout>
  );
};
