import React, { useState, useContext, useEffect } from "react";
import { Radio, Form, Modal, message, Col, Button } from "antd";
import Common from "./common";
import IndividualSlide2 from "./individual/IndividualSlide2";
import IndividualSlide1 from "./individual/IndividualSlide1";
import {
  DILIGENCE_FORMS,
  TRUST_FOUNDATION_TYPES,
  CORPORATE_ENTITY_TYPES,
} from "../../utils/constants";
import {
  eraseDiligenceFormsByUser,
  createDilligenceForm,
  updateDilligenceForm,
  updateUser,
} from "../../utils/api";
import {
  AuthStateContext,
  AuthDispatchContext,
} from "../../context/AuthContextProvider";
import {
  UserDispatchContext,
  UserStateContext,
} from "../../context/UserContextProvider";
import { getCookie } from "../../utils/getCookie";
import EntitySlide1 from "./entity/EntitySlide1";
import EntitySlide1p5 from "./entity/EntitySlide1.5";
import EntitySlide3 from "./entity/EntitySlide3";
import {
  getArrayofPartners,
  getArrayOfShareHolders,
} from "../../utils/formUtils";

export function Edit() {
  const [form] = Form.useForm();
  const { accessToken } = useContext(AuthStateContext);
  const UserDispatch = useContext(UserDispatchContext);
  const AuthDispatch = useContext(AuthDispatchContext);
  const { user: targetUser, diligenceForm } = useContext(UserStateContext);
  const [diligenceFormTypeId, setDiligenceFromTypeId] = useState(
    DILIGENCE_FORMS.INDIVIDUAL
  );
  const [confirmModalVisible, setConfirmModalVisibile] = useState("");
  const [isDeletingDiligences, setIsDeletingDiligences] = useState(false);
  const token = getCookie("accessToken") || accessToken;
  const [isLoading, setIsLoading] = useState(false);
  const [creatingDiligenceForm, setCreatingDiligenceForm] = useState(false);
  const [df, setDF] = useState(null);

  const [shareholders, setShareHolders] = useState([0]);
  const [partners, setPartners] = useState([0]);

  const [partnersDocuments, setPartnersDocuments] = useState({});
  const [shareholdersDocuments, setShareholdersDocuments] = useState({});

  useEffect(() => {
    setDiligenceFromTypeId(
      (diligenceForm && diligenceForm.diligenceForm) ||
        DILIGENCE_FORMS.INDIVIDUAL
    );
    setDF(diligenceForm);
  }, [diligenceForm]);

  useEffect(() => {
    AuthDispatch({ type: "set", payload: { accessToken: token } });
  }, [token, AuthDispatch]);

  const syncData = (extra, dispatchContext = false) => {
    if (creatingDiligenceForm) return;
    if (targetUser.selectedDiligenceForm !== diligenceFormTypeId) {
      updateUser(targetUser.id, token, {
        selectedDiligenceForm: diligenceFormTypeId,
      });
    }
    let prms;
    if (diligenceFormTypeId === DILIGENCE_FORMS.INDIVIDUAL) {
      const values: any = form.getFieldsValue();

      const sanitizedValues: any = { ...extra, userId: targetUser.id };

      [
        "nationalities",
        "birthCountry",
        "birthCity",
        "birthDate",
        "address",
        "addressDetail",
        "isBirthPlaceNationality",
      ].forEach(key => {
        if (typeof values[key] !== "undefined")
          sanitizedValues[key] = values[key];
      });
      if (!diligenceForm) {
        setCreatingDiligenceForm(true);
        prms = createDilligenceForm(DILIGENCE_FORMS.INDIVIDUAL)(
          accessToken,
          sanitizedValues
        );
      } else {
        prms = updateDilligenceForm(
          diligenceForm.id,
          accessToken,
          sanitizedValues
        );
      }
    } else {
      const values: any = Object.entries(form.getFieldsValue()).reduce(
        (acc, e) => (e[1] ? { ...acc, [e[0]]: e[1] } : acc),
        {}
      );
      const {
        name,
        registrationId,
        countryOfIncorporation,
        dateOfIncorporation,
        entityType,
        address,
        addressDetail,
      } = values;
      const releventValues = {
        ...extra,
        name,
        registrationId,
        countryOfIncorporation,
        dateOfIncorporation,
        entityType,
        address,
        addressDetail,
        userId: targetUser.id,
        // partners
        partners: getArrayofPartners(
          values.partnersStatus,
          values,
          partnersDocuments,
          partners
        ),
        partnersStatus: values.partnersStatus,
        shareHolders: getArrayOfShareHolders(
          values.shareHoldersStatus,
          values,
          shareholdersDocuments,
          shareholders
        ),
        shareHoldersStatus: values.shareHoldersStatus,
      };

      if (!df) {
        setCreatingDiligenceForm(true);
        prms = createDilligenceForm(diligenceFormTypeId)(
          accessToken,
          releventValues
        );
      } else {
        prms = updateDilligenceForm(df.id, accessToken, releventValues);
      }
    }
    if (prms) {
      prms
        .then(async r => {
          if (r.ok) {
            const { diligenceForm: dil } = await r.json();
            setDF(dil);
            if (!diligenceForm || dispatchContext)
              UserDispatch({
                type: "set",
                payload: { diligenceForm: dil },
              });
          }
        })
        .finally(() => {
          setCreatingDiligenceForm(false);
          setIsLoading(false);
        });
    }
  };

  return (
    <div style={{ maxWidth: "700px", width: "100%", margin: "0 auto" }}>
      <Modal
        title="Alert"
        visible={(confirmModalVisible && true) || false}
        onOk={() => {
          setIsDeletingDiligences(true);
          eraseDiligenceFormsByUser({
            userId: targetUser.id,
            token,
          })
            .then(r => {
              if (r.ok) {
                UserDispatch({
                  type: "set",
                  payload: {
                    diligenceForm: null,
                    documents: null,
                    userDocuments: null,
                    // activeDiligenceFormTypeId:
                  },
                });
                message.success("Diligence erased with success");
                setDiligenceFromTypeId(confirmModalVisible);
                setConfirmModalVisibile("");
              }
            })
            .finally(() => {
              setIsDeletingDiligences(false);
            });
        }}
        onCancel={() => {
          setConfirmModalVisibile("");
        }}
        okText="Yes erase it"
        okType="danger"
        okButtonProps={{ loading: isDeletingDiligences }}
      >
        <p>Are you sure you want to switch the diligence type</p>
        <p>
          be aware that hitting ok will erase all the data saved for this
          diligence form
        </p>
      </Modal>
      <Common />

      <div className="signup_slide" style={{ marginTop: 20 }}>
        <Form.Item
          label="You are / Represent a :"
          className="label-title"
          initialValue={DILIGENCE_FORMS.INDIVIDUAL}
        >
          <Radio.Group
            className="diligenceType"
            style={{ marginTop: 20 }}
            onChange={e => {
              if (df) setConfirmModalVisibile(e.target.value);
              else setDiligenceFromTypeId(e.target.value);
            }}
            value={diligenceFormTypeId}
          >
            <Radio value={DILIGENCE_FORMS.INDIVIDUAL}>
              <div className="circle" />
              Individual / Sole proprietor
            </Radio>
            <Radio value={DILIGENCE_FORMS.CORPORATE_ENTITY}>
              <div className="circle" />
              Corporate entity
            </Radio>
            <Radio value={DILIGENCE_FORMS.TRUST_FOUNDATION}>
              <div className="circle" />
              Trust / Foundation
            </Radio>
          </Radio.Group>
        </Form.Item>
      </div>

      <Form
        form={form}
        layout="vertical"
        name="entity-1"
        size="large"
        className="entity"
        onFinish={() => {
          setIsLoading(true);

          const {
            isAttachedUserAPep,
            attachedUserRole,
          } = form.getFieldsValue();
          syncData(
            {
              isAttachedUserAPep,
              attachedUserRole,
            },
            true
          );
        }}
        onBlur={e => {
          if (e.target.nodeName !== "BUTTON") {
            if (diligenceFormTypeId === DILIGENCE_FORMS.INDIVIDUAL) {
              const { originOfAssets } = form.getFieldsValue();

              syncData({
                keepUnusedDocs: true,
                originOfAssets: originOfAssets.map(elm => ({
                  origin: elm,
                  detail: form.getFieldValue(`sourceOfAssets-${elm}`),
                })),
              });
            } else {
              const { attachedUserRole } = form.getFieldsValue();
              syncData({ keepUnusedDocs: true, attachedUserRole });
            }
          }
        }}
        scrollToFirstError
      >
        {diligenceFormTypeId === DILIGENCE_FORMS.INDIVIDUAL ? (
          <div>
            <IndividualSlide1 form={form} />
            <div style={{ marginTop: 40 }}>
              <IndividualSlide2 form={form} />
            </div>
          </div>
        ) : diligenceFormTypeId === DILIGENCE_FORMS.CORPORATE_ENTITY ? (
          <div>
            <EntitySlide1
              diligenceFormId={DILIGENCE_FORMS.CORPORATE_ENTITY}
              entityTypes={CORPORATE_ENTITY_TYPES}
              form={form}
            />
            <EntitySlide1p5
              form={form}
              diligenceFormId={DILIGENCE_FORMS.CORPORATE_ENTITY}
            />
            <EntitySlide3
              form={form}
              shareholders={shareholders}
              partners={partners}
              onShareHoldersChange={setShareHolders}
              onPartnersChange={setPartners}
              partnersDocuments={partnersDocuments}
              shareholdersDocuments={shareholdersDocuments}
              onPartnersDocumentsChange={setPartnersDocuments}
              onShareholdersDocumentsChange={setShareholdersDocuments}
            />
          </div>
        ) : (
          <div>
            <EntitySlide1
              form={form}
              diligenceFormId={DILIGENCE_FORMS.TRUST_FOUNDATION}
              entityTypes={TRUST_FOUNDATION_TYPES}
            />
            <EntitySlide1p5
              form={form}
              diligenceFormId={DILIGENCE_FORMS.TRUST_FOUNDATION}
            />
            <EntitySlide3
              form={form}
              shareholders={shareholders}
              partners={partners}
              onShareHoldersChange={setShareHolders}
              onPartnersChange={setPartners}
              partnersDocuments={partnersDocuments}
              shareholdersDocuments={shareholdersDocuments}
              onPartnersDocumentsChange={setPartnersDocuments}
              onShareholdersDocumentsChange={setShareholdersDocuments}
            />
          </div>
        )}

        <Col
          xs={24}
          md={{ span: 10, offset: 14 }}
          style={{ textAlign: "right" }}
        >
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
            loading={isLoading}
            block
            size="large"
            className="main-btn"
          >
            Validate
          </Button>
        </Col>
      </Form>
    </div>
  );
}
