import React, { useContext, useEffect } from "react";
import { Switch, Form, Popover } from "antd";
import { UserStateContext } from "../../../context/UserContextProvider";
import { OriginOfBusiness } from "../../signup/OriginOfBusiness";
// @ts-ignore
import tooltipIcon from "../../../images/tooltip.svg";
// @ts-ignore
function IndividualSlide2({ form }: any) {
  const { diligenceForm } = useContext(UserStateContext);

  const tooltipText = (
    <div style={{ maxWidth: 500, background: "black", color: "#FFF" }}>
      A PEP is an individual who is or has been entrusted with a prominent
      public function in a foreign country (for example: head of state or of
      government, senior politician, senior government, judicial or military
      officials, senior executives of state owned corporations, important
      political party officials).
    </div>
  );
  useEffect(() => {
    if (diligenceForm) {
      const {
        paGt300T,
        assetsGt2m,
        isRepresenter,
        isClean,
        isAPep,
        originOfAssets,
      } = diligenceForm;
      const formValues: any = {
        isAPep,
        paGt300T,
        assetsGt2m,
        isRepresenter,
        isClean,
      };

      if (Array.isArray(originOfAssets)) {
        formValues.originOfAssets = originOfAssets.map(e => {
          formValues[`sourceOfAssets-${e.origin}`] = e.detail;
          return e.origin;
        });
      }
      form.setFieldsValue(formValues);
    }
  }, [diligenceForm]);

  return (
    <div className="signup_slide">
      <div className="section-title">Accredited investor</div>
      <div className="section-row d-flex justify-content-between align-items-center w-100">
        <p>
          Does your total income (p.a.) in the preceding 12 months exceeding
          $300’000 (or its equivalent in a foreign currency) ?
        </p>
        <Form.Item
          initialValue={false}
          name="paGt300T"
          valuePropName="checked"
          style={{ marginBottom: 0 }}
        >
          <Switch />
        </Form.Item>
      </div>
      <div className="section-row d-flex justify-content-between align-items-center w-100">
        <p>
          Does your total assets exceeding $ 2 million <br />
          (or its equivalent in a foreign currency) ?{" "}
        </p>
        <Form.Item
          initialValue={false}
          name="assetsGt2m"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </div>
      <OriginOfBusiness form={form} />
      <div className="section-title">POLITICAL EXPOSITION</div>
      <div className="section-row d-flex justify-content-between align-items-center w-100">
        <div className="d-flex align-items-center">
          Are you considered as a Political Exposed Person ?{" "}
          <div className="main-tooltip">
            <Popover placement="bottom" content={tooltipText}>
              <span>
                <img src={tooltipIcon} alt="" style={{ marginLeft: 10 }} />{" "}
              </span>
            </Popover>
          </div>
        </div>
        <Form.Item initialValue={false} name="isAPep" valuePropName="checked">
          <Switch />
        </Form.Item>
      </div>

      <div className="section-title" style={{ marginTop: 20 }}>
        Customer declaration
      </div>
      <div className="section-row d-flex justify-content-between align-items-start">
        <p>
          Is the beneficial owner of the funds used to transact with GPM and of
          the assets held in storage with GPM (The Customer’s Assets){" "}
        </p>
        <Form.Item
          name="isRepresenter"
          initialValue={false}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </div>
      <div className="section-row d-flex justify-content-between align-items-start">
        <p>
          Your assets do not represent the proceeds of and are not intended to
          finance or be used in connection with any act which may constitute
          drug trafficking or criminal conduct or any terrorist act or tax
          evasion in any country in which you are subject to tax or product of
          serious offense, as defined by the Singapore Corruption, Drug
          Trafficking and Other Serious Crimes Act
        </p>
        <Form.Item name="isClean" valuePropName="checked">
          <Switch />
        </Form.Item>
      </div>
    </div>
  );
}

export default IndividualSlide2;
