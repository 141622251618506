import React, { useContext, useState, useEffect } from "react";
import { Form, Upload, message, Switch, Popover, Select } from "antd";
// @ts-ignore
import tooltipIcon from "../../../images/tooltip.svg";
// @ts-ignore// @ts-ignore
import UploadFileIcon from "../../../images/upload-files-icon.svg";
import {
  UserStateContext,
  UserDispatchContext,
} from "../../../context/UserContextProvider";
import {
  DILIGENCE_FORMS,
  ENDPOINTS,
  COMPANY_ROLES,
  COMPANY_ROLES_LABELS,
} from "../../../utils/constants";
import { createDilligenceForm } from "../../../utils/api";
import { AuthStateContext } from "../../../context/AuthContextProvider";
import { deleteUploadedDocument } from "../../../utils/deleteUploadedDocument";
import RequiredFile from "../../common/RequiredFile";

function CorporateEntitySlide1({ form }: any) {
  const { user, diligenceForm, userDocuments } = useContext(UserStateContext);
  const { accessToken } = useContext(AuthStateContext);
  const UserDispatch = useContext(UserDispatchContext);

  const [upladedDocuments, setUpladedDocuments] = useState([]);

  useEffect(() => {
    setUpladedDocuments(
      (userDocuments || []).map((d, j) => ({
        uid: d.uid || j,
        status: d.status || "done",
        response: { document: d, url: d.url },
        url: d.url,
      }))
    );
  }, [userDocuments]);

  useEffect(() => {
    if (diligenceForm) {
      const { isAttachedUserAPep, attachedUserRole } = diligenceForm;
      form.setFieldsValue({ isAttachedUserAPep, attachedUserRole });
    }
  }, [diligenceForm]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 700,
        width: "100%",
        margin: "0 auto",
        marginTop: 50,
      }}
    >
      <h3 className="section-title">ABOUT YOU:</h3>
      <div className="entityAboutUser">
        <div className="entityAboutUser_title" style={{ marginBottom: 24 }}>
          PLease upload the following documents for{" "}
          <span className="capitalized">{(user && user.lname) || ""}</span>
        </div>
        <div className="upload-section">
          <RequiredFile text="ID or Passeport " />
          <Form.Item>
            <Upload.Dragger
              className="documents-dragger"
              name="file"
              listType="picture-card"
              multiple
              data={{
                for: "user",
                userId: user && user.id,
                diligenceFormId: diligenceForm && diligenceForm.id,
              }}
              // action={`${ENDPOINTS.DILIGENCE_FORM}/${
              //   diligenceForm && diligenceForm.id
              // }/document`}
              action={(): Promise<string> => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (res, rej) => {
                  try {
                    let dil = diligenceForm;
                    if (!diligenceForm) {
                      // if (isCreatingDiligenceForm) rej();
                      // setIsCreatingDiligenceForm(true);
                      const re = await createDilligenceForm(
                        DILIGENCE_FORMS.INDIVIDUAL
                      )(accessToken, {});
                      dil = (await re.json()).diligenceForm;
                      UserDispatch({
                        type: "set",
                        payload: { diligenceForm: dil },
                      });
                      // setDiligenceForm(dil);
                      // setIsCreatingDiligenceForm(false);
                    }
                    res(`${ENDPOINTS.DILIGENCE_FORM}/${dil.id}/document`);
                  } catch (ee) {
                    rej(ee);
                  }
                });
              }}
              headers={{
                Authorization: `Bearer ${accessToken}`,
              }}
              onChange={info => {
                const { status } = info.file;

                const fileList = [...info.fileList];
                setUpladedDocuments(
                  fileList.map(file => {
                    if (file.response && file.response.document) {
                      // Component will show file.url as link

                      // eslint-disable-next-line no-param-reassign
                      file.url = file.response.document.url;
                    }
                    return file;
                  })
                );

                if (status !== "uploading") {
                  // console.log(info.file, info.fileList);
                }
                if (status === "done") {
                  message.success(
                    `${info.file.name} file uploaded successfully.`
                  );
                } else if (status === "error") {
                  message.error(`${info.file.name} file upload failed.`);
                }
              }}
              onRemove={deleteUploadedDocument(accessToken)}
              fileList={upladedDocuments}
            >
              <div className="dragArea">
                <img src={UploadFileIcon} alt="UploadIcon" />
                <div className="dragArea_uploadTextConatiner">
                  <div className="dragArea_uploadTitle">
                    Drag your files here
                  </div>
                  <div className="dragArea_uploadDesc">
                    Support extension: zip .doc .docx .pdf .jpg...
                  </div>
                </div>
                <div className="dragArea_uploadBtn">Upload a document</div>
              </div>
            </Upload.Dragger>
          </Form.Item>
        </div>
        <Form.Item
          label={`SELECT A ROLE IN THE ${diligenceForm && diligenceForm.name}`}
          name="attachedUserRole"
          rules={[
            {
              required: true,
              message: "Please select your role",
            },
          ]}
        >
          <Select placeholder="Role">
            {Object.values(COMPANY_ROLES).map(e => (
              <Select.Option key={e} value={e}>
                {COMPANY_ROLES_LABELS[e]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="section-row d-flex justify-content-between align-items-center">
          <div>
            Are you considered as a Political Exposed Person ?{" "}
            <Popover
              placement="bottom"
              content={
                <div style={{ color: "#FFF", maxWidth: 500 }}>
                  A PEP is an individual who is or has been entrusted with a
                  prominent public function in a foreign country (for example:
                  head of state or of government, senior politician, senior
                  government, judicial or military officials, senior executives
                  of state owned corporations, important political party
                  officials).
                </div>
              }
              arrowPointAtCenter
            >
              <span>
                <img src={tooltipIcon} alt="" style={{ marginLeft: 10 }} />{" "}
              </span>
            </Popover>
          </div>
          <Form.Item
            initialValue={false}
            name="isAttachedUserAPep"
            style={{ marginBottom: 0 }}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </div>
      </div>
    </div>
  );
}

export default CorporateEntitySlide1;
