import React, { useContext, useEffect } from "react";
import { Select, Input, Row, Col, Button, Form, message, Upload } from "antd";

import {
  SHAREHOLDERS_STATUS,
  TITLES,
  DILIGENCE_FORMS,
  ENDPOINTS,
} from "../../../utils/constants";
import { createDilligenceForm } from "../../../utils/api";
import {
  UserStateContext,
  UserDispatchContext,
} from "../../../context/UserContextProvider";
import { AuthStateContext } from "../../../context/AuthContextProvider";
import {
  getPartnersObject,
  getShareholdersObject,
} from "../../../utils/formUtils";
// @ts-ignore
import UploadFileIcon from "../../../images/upload-files-icon.svg";
import { IsPepItem } from "../../signup/IsPepItem";
import { deleteUploadedDocument } from "../../../utils/deleteUploadedDocument";

const { Option } = Select;

function EntitySlide3({
  form,
  shareholders,
  partners,
  onShareHoldersChange,
  onPartnersChange,
  partnersDocuments,
  shareholdersDocuments,
  onPartnersDocumentsChange,
  onShareholdersDocumentsChange,
}: any) {
  const { diligenceForm, user } = useContext(UserStateContext);
  const { accessToken } = useContext(AuthStateContext);

  const UserDispatch = useContext(UserDispatchContext);

  useEffect(() => {
    if (diligenceForm) {
      const {
        shareHoldersStatus,
        partnersStatus,
        shareHolders: oldShareHolders,
        partners: oldPartners,
      } = diligenceForm;
      onPartnersDocumentsChange(
        diligenceForm.partners.reduce(
          (acc, e, i) => ({
            ...acc,
            [i]: e.documents.flatMap((d, j) =>
              d
                ? [
                    {
                      uid: d.uid || j,
                      status: d.status || "done",
                      response: { document: d, url: d.url },
                      url: d.url,
                    },
                  ]
                : []
            ),
          }),
          {}
        )
      );

      onShareholdersDocumentsChange(
        diligenceForm.shareHolders.reduce(
          (acc, e, i) => ({
            ...acc,
            [i]: e.documents.flatMap((d, j) =>
              d
                ? [
                    {
                      uid: d.uid || j,
                      status: d.status || "done",
                      response: { document: d, url: d.url },
                      url: d.url,
                    },
                  ]
                : []
            ),
          }),
          {}
        )
      );
      onPartnersChange(
        oldPartners.length > 0
          ? new Array(oldPartners.length).fill(1).map((e, i) => i)
          : [0]
      );
      onShareHoldersChange(
        oldShareHolders.length > 0
          ? new Array(oldShareHolders.length).fill(1).map((e, i) => i)
          : [0]
      );
      const formValues: any = {
        shareHoldersStatus,
        partnersStatus,
        ...getPartnersObject(oldPartners),
        ...getShareholdersObject(oldShareHolders),
      };

      form.setFieldsValue(formValues);
    }
  }, [diligenceForm]);

  return (
    <div style={{ marginTop: 50 }}>
      <h3 className="section-title">DIRECTORS OR PARTNERS</h3>

      <Form.Item
        name="partnersStatus"
        label="Do you have directors or partners to add ?"
        initialValue={SHAREHOLDERS_STATUS.NO}
        rules={[
          {
            required: true,
            message: "Please select this",
          },
        ]}
      >
        <Select placeholder="Select a type">
          <Option key={SHAREHOLDERS_STATUS.YES} value={SHAREHOLDERS_STATUS.YES}>
            Yes, I want to add them now
          </Option>
          <Option key={SHAREHOLDERS_STATUS.NO} value={SHAREHOLDERS_STATUS.NO}>
            No, I don’t have any
          </Option>
          <Option
            key={SHAREHOLDERS_STATUS.LATER}
            value={SHAREHOLDERS_STATUS.LATER}
          >
            I want to add them later
          </Option>
        </Select>
      </Form.Item>

      <Form.Item shouldUpdate={(o, n) => o.partnersStatus !== n.partnersStatus}>
        {() =>
          form.getFieldValue("partnersStatus") === SHAREHOLDERS_STATUS.YES ? (
            <div className="editCont">
              <div className="editCont__body">
                {partners.map(i => {
                  return (
                    <div
                      className="editCont__item"
                      key={`part-${i}`}
                      style={{ paddingBottom: 20 }}
                    >
                      <Row gutter={12}>
                        <Col xs={24} md={3}>
                          <Form.Item
                            name={`partnerTitle-${i}`}
                            rules={[
                              {
                                required: true,
                                message: "Please input   first name",
                              },
                            ]}
                            label="Title"
                            initialValue={TITLES.MR}
                          >
                            <Select placeholder="Title">
                              {Object.values(TITLES).map(elm => (
                                <Option
                                  key={elm.toLowerCase()}
                                  value={elm.toLowerCase()}
                                >
                                  {elm}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={9}>
                          <Form.Item
                            name={`partnerFname-${i}`}
                            rules={[
                              {
                                required: true,
                                message: "Please input   first name",
                              },
                            ]}
                            label="First Name"
                          >
                            <Input placeholder="First name" />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                          <Form.Item
                            name={`partnerLname-${i}`}
                            rules={[
                              {
                                required: true,
                                message: "Please input  lirst name",
                              },
                            ]}
                            label="Last Name"
                          >
                            <Input placeholder="Last name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item>
                        <Upload.Dragger
                          className="documents-dragger"
                          name="file"
                          listType="picture-card"
                          fileList={partnersDocuments[i] || []}
                          multiple
                          data={{
                            for: "partner",
                            userId: user && user.id,
                            diligenceFormId: diligenceForm && diligenceForm.id,
                          }}
                          action={(): Promise<string> => {
                            // eslint-disable-next-line no-async-promise-executor
                            return new Promise(async (res, rej) => {
                              try {
                                let dil = diligenceForm;
                                if (!diligenceForm) {
                                  // if (isCreatingDiligenceForm) rej();
                                  // setIsCreatingDiligenceForm(true);
                                  const re = await createDilligenceForm(
                                    DILIGENCE_FORMS.INDIVIDUAL
                                  )(accessToken, {});
                                  dil = (await re.json()).diligenceForm;
                                  UserDispatch({
                                    type: "set",
                                    payload: { diligenceForm: dil },
                                  });
                                  // setDiligenceForm(dil);
                                  // setIsCreatingDiligenceForm(false);
                                }
                                res(
                                  `${ENDPOINTS.DILIGENCE_FORM}/${dil.id}/document`
                                );
                              } catch (ee) {
                                rej(ee);
                              }
                            });
                          }}
                          headers={{
                            Authorization: `Bearer ${accessToken}`,
                          }}
                          onChange={info => {
                            const { status } = info.file;

                            const fileList = [...info.fileList];

                            onPartnersDocumentsChange({
                              ...partnersDocuments,
                              [i]: fileList.map(file => {
                                if (file.response && file.response.document) {
                                  // eslint-disable-next-line no-param-reassign
                                  file.url = file.response.document.url;
                                }
                                return file;
                              }),
                            });

                            if (status === "done") {
                              message.success(
                                `${info.file.name} file uploaded successfully.`
                              );
                            } else if (status === "error") {
                              message.error(
                                `${info.file.name} file upload failed.`
                              );
                            }
                          }}
                          onRemove={deleteUploadedDocument(accessToken)}
                        >
                          <div className="dragArea">
                            <img src={UploadFileIcon} alt="UploadIcon" />
                            <div className="dragArea_uploadTextConatiner">
                              <div className="dragArea_uploadTitle">
                                Drag your files here
                              </div>
                              <div className="dragArea_uploadDesc">
                                Support extension: zip .doc .docx .pdf .jpg...
                              </div>
                            </div>
                            <div className="dragArea_uploadBtn">
                              Upload a document
                            </div>
                          </div>
                        </Upload.Dragger>
                      </Form.Item>
                      <IsPepItem name={`partnersIsPep-${i}`} />
                      <Button
                        onClick={() => {
                          onPartnersChange(o => o.filter(e => e !== i));
                          form.resetFields([
                            `partnerTitle-${i}`,
                            `partnerFname-${i}`,
                            `partnerLname-${i}`,
                            `partnersIsPep-${i}`,
                          ]);
                          onPartnersChange(o => ({
                            ...o,
                            [i]: [],
                          }));
                        }}
                      >
                        remove
                      </Button>
                    </div>
                  );
                })}
              </div>
              <Button
                className="editCont__btn"
                onClick={() => onPartnersChange(o => [...o, o.length + 1])}
                block
              >
                + Add another director/partner ?
              </Button>
            </div>
          ) : null
        }
      </Form.Item>

      <h3 className="section-title">
        SHAREHOLDERS & ULTIMATE BENEFICIAL OWNERS
      </h3>

      <Form.Item
        name="shareHoldersStatus"
        label="Do you have shareholders to add ?"
        initialValue={SHAREHOLDERS_STATUS.NO}
        rules={[
          {
            required: true,
            message: "Please select this",
          },
        ]}
      >
        <Select placeholder="Select a type">
          <Option key={SHAREHOLDERS_STATUS.YES} value={SHAREHOLDERS_STATUS.YES}>
            Yes, I want to add them now
          </Option>
          <Option key={SHAREHOLDERS_STATUS.NO} value={SHAREHOLDERS_STATUS.NO}>
            No, I don’t have any
          </Option>
          <Option
            key={SHAREHOLDERS_STATUS.LATER}
            value={SHAREHOLDERS_STATUS.LATER}
          >
            I want to add them later
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        shouldUpdate={(o, n) => o.shareHoldersStatus !== n.shareHoldersStatus}
      >
        {() =>
          form.getFieldValue("shareHoldersStatus") ===
          SHAREHOLDERS_STATUS.YES ? (
            <div className="editCont">
              <div className="editCont__body">
                {shareholders.map(i => (
                  <div
                    key={`share-${i}`}
                    className="editCont__item"
                    style={{ paddingBottom: 20 }}
                  >
                    <Row gutter={12}>
                      <Col xs={24} md={3}>
                        <Form.Item
                          name={`shareholderTitle-${i}`}
                          rules={[
                            {
                              required: true,
                              message: "Please input   first name",
                            },
                          ]}
                          label="Title"
                          initialValue={TITLES[0]}
                        >
                          <Select placeholder="Title">
                            {Object.values(TITLES).map(elm => (
                              <Option key={elm} value={elm}>
                                {elm}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={24} md={9}>
                        <Form.Item
                          name={`shareholderFname-${i}`}
                          rules={[
                            {
                              required: true,
                              message: "Please input   first name",
                            },
                          ]}
                          label="First Name"
                        >
                          <Input placeholder="First name" />
                        </Form.Item>
                      </Col>

                      <Col xs={24} md={12}>
                        <Form.Item
                          name={`shareholderLname-${i}`}
                          rules={[
                            {
                              required: true,
                              message: "Please input  lirst name",
                            },
                          ]}
                          label="Last Name"
                        >
                          <Input placeholder="Last name" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <Upload.Dragger
                        className="documents-dragger"
                        name="file"
                        listType="picture-card"
                        fileList={shareholdersDocuments[i] || []}
                        multiple
                        data={{
                          for: "shareholder",
                          userId: user && user.id,
                          diligenceFormId: diligenceForm && diligenceForm.id,
                        }}
                        action={(): Promise<string> => {
                          // eslint-disable-next-line no-async-promise-executor
                          return new Promise(async (res, rej) => {
                            try {
                              let dil = diligenceForm;
                              if (!diligenceForm) {
                                // if (isCreatingDiligenceForm) rej();
                                // setIsCreatingDiligenceForm(true);
                                const re = await createDilligenceForm(
                                  DILIGENCE_FORMS.INDIVIDUAL
                                )(accessToken, {});
                                dil = (await re.json()).diligenceForm;
                                UserDispatch({
                                  type: "set",
                                  payload: { diligenceForm: dil },
                                });
                                // setDiligenceForm(dil);
                                // setIsCreatingDiligenceForm(false);
                              }
                              res(
                                `${ENDPOINTS.DILIGENCE_FORM}/${dil.id}/document`
                              );
                            } catch (ee) {
                              rej(ee);
                            }
                          });
                        }}
                        headers={{
                          Authorization: `Bearer ${accessToken}`,
                        }}
                        onChange={info => {
                          const { status } = info.file;

                          const fileList = [...info.fileList];
                          onShareholdersDocumentsChange({
                            ...shareholdersDocuments,
                            [i]: fileList.map(file => {
                              if (file.response && file.response.document) {
                                // eslint-disable-next-line no-param-reassign
                                file.url = file.response.document.url;
                              }
                              return file;
                            }),
                          });

                          if (status === "done") {
                            message.success(
                              `${info.file.name} file uploaded successfully.`
                            );
                          } else if (status === "error") {
                            message.error(
                              `${info.file.name} file upload failed.`
                            );
                          }
                        }}
                        onRemove={deleteUploadedDocument(accessToken)}
                      >
                        <div className="dragArea">
                          <img src={UploadFileIcon} alt="UploadIcon" />
                          <div className="dragArea_uploadTextConatiner">
                            <div className="dragArea_uploadTitle">
                              Drag your files here
                            </div>
                            <div className="dragArea_uploadDesc">
                              Support extension: zip .doc .docx .pdf .jpg...
                            </div>
                          </div>
                          <div className="dragArea_uploadBtn">
                            Upload a document
                          </div>
                        </div>
                      </Upload.Dragger>
                    </Form.Item>
                    <IsPepItem name={`shareholderIsPep-${i}`} />{" "}
                    <Button
                      onClick={() => {
                        onShareHoldersChange(o => o.filter(e => e !== i));
                        form.resetFields([
                          `shareholderTitle-${i}`,
                          `shareholderFname-${i}`,
                          `shareholderLname-${i}`,
                          `shareholderIsPep-${i}`,
                        ]);
                        onShareHoldersChange(o => ({
                          ...o,
                          [i]: [],
                        }));
                      }}
                    >
                      remove
                    </Button>
                  </div>
                ))}
              </div>
              <Button
                className="editCont__btn"
                onClick={() => onShareHoldersChange(o => [...o, o.length + 1])}
                block
              >
                + Add another shareholder ?
              </Button>
            </div>
          ) : null
        }
      </Form.Item>
    </div>
  );
}

export default EntitySlide3;
